<template>
  <div class="app-wrapper">
    <BaseNavigation />
    <DividerNavigation />
    <div class="p-2 mx-auto">
      <div class="d-flex flex-row">
        <div style="width: 50%" class="pr-25">
          <b-button
            :variant="`${!FilterSipUmum ? 'outline-' : ''}danger`"
            block
            @click="FilterSipUmum = !FilterSipUmum"
          >
            {{ FilterSipUmum ? "Batalkan " : "Filter" }}
          </b-button>
        </div>
        <div style="width: 50%" class="pr-25">
          <b-button
            :variant="`${!SortSipUmum ? 'outline-' : ''}danger`"
            block
            @click="SortSipUmum = !SortSipUmum"
          >
            {{ SortSipUmum ? "Batalkan " : "Sort" }}
          </b-button>
        </div>
        <b-button
          variant="outline-danger"
          block
          @click="$router.push('/rekomendasi/umum/create')"
        >
          Permintaan Baru
        </b-button>
      </div>
      <!-- filter by sip umum-->
      <b-form v-if="FilterSipUmum" class="mt-1" @submit.prevent>
        <validation-observer ref="FilterSipUmum">
          <b-form-group label="Nama Instansi" label-for="reksip_nama_instansi">
            <validation-provider
              #default="{ errors }"
              name="reksip_nama_instansi"
            >
              <b-form-input
                id="reksip_nama_instansi"
                v-model="filter.reksip_nama_instansi"
                :state="errors.length > 0 ? false : null"
                name="reksip_nama_instansi"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Alamat Instansi"
            label-for="reksip_alamat_instansi"
          >
            <validation-provider
              #default="{ errors }"
              name="reksip_alamat_instansi"
            >
              <b-form-input
                id="reksip_alamat_instansi"
                v-model="filter.reksip_alamat_instansi"
                :state="errors.length > 0 ? false : null"
                name="reksip_alamat_instansi"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="outline-danger"
            block
            @click="validateFilterKRIP"
          >
            Search
          </b-button>
        </validation-observer>
        <hr />
      </b-form>
      <!-- sort by sip umum-->
      <b-form v-if="SortSipUmum" class="mt-1" @submit.prevent>
        <validation-observer ref="SortSipUmum">
          <b-form-group label="Type Sort" label-for="type_sort">
            <validation-provider #default="{ errors }" name="type_sort">
              <b-form-select
                v-model="filter.typeSort"
                :state="errors.length > 0 ? false : null"
                :options="optionTypeSort"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="outline-danger"
            block
            @click="validateSortSipUmum"
          >
            Sort
          </b-button>
        </validation-observer>
        <hr />
      </b-form>

      <div
        v-if="rekomendasi.isLoading"
        class="d-flex justify-content-center p-1 mb-1"
      >
        <b-spinner label="Loading..." variant="danger" />
      </div>
      <div class="mt-1">
        <b-card
          v-for="item in rekomendasi.data"
          :key="item.id"
          class="shadow-none border p-1 mb-1"
          no-body
        >
          <div class="d-flex pb-1 border-bottom">
            <div>
              <div
                class="font-weight-bold"
                v-if="
                  item.reksip_kategori != 'spesialis' &&
                  item.reksip_kategori != 'Spesialis'
                "
              >
                #{{ item.id }} - {{ item.reksip_kategori }}
              </div>
              <!-- label status verif rekom izin praktek-->
              <b-badge
                v-if="item.reksip_terbit === true"
                variant="success font-weight–light mt-25"
              >
                Sudah Terbit
              </b-badge>
              <b-badge v-else variant="warning font-weight–light mt-25">
                Belum Terbit
              </b-badge>
            </div>
            <div class="ml-auto d-flex text-danger">
              <div
                v-if="item.reksip_terbit == false"
                class="pointer mr-2"
                @click="$router.push(`/rekomendasi/umum/${item.id}/edit`)"
              >
                <feather-icon icon="EditIcon" size="20" class="align-middle" />
              </div>
              <div v-if="item.reksip_terbit === false" class="pointer">
                <feather-icon
                  icon="XIcon"
                  size="20"
                  class="align-middle"
                  @click="CancelRekomendasiUmum(item.id)"
                />
              </div>
            </div>
          </div>
          <div class="pt-1">
            <div
              v-if="item.reksip_terbit === false"
              class="card-content card-content-padding"
              style="text-align: justify"
            >
              <p class="">
                Proses pada pelayanan ini belum selesai silahkan menghubungi
                pihak admin .
              </p>
              <p class="p-0 mb-0">
                untuk melakukan verifikasi invoice anda serta mengaktifkan
                rekomendasi izin praktek.
              </p>
              <br />
            </div>
            <div
              v-else
              class="card-content card-content-padding"
              style="text-align: justify"
            >
              <p class="">
                Selamat Rekomendasi Izin Praktik Anda Sudah Terbit.
                <br />
                <br />
                Faskes: {{ item.reksip_nama_instansi }}
                <br />
                Alamat: {{ item.reksip_alamat_instansi }}
              </p>
            </div>
          </div>
          <div class="d-flex flex-column mt-25">
            <div class="font-weight-bold mb-25">File Rekomendasi Sah</div>
            <div>
              <b-img
                v-if="!isPDF(item)"
                :src="fileSahRekomTerbit(item)"
                style="max-width: 200px; max-height: 200px"
                fluid
                center
              />
              <div
                v-else
                class="text-primary text-underline pointer"
                @click="viewPdf(fileSahRekomTerbit(item))"
              >
                <u>Lihat Berkas Rekom Sah</u>
              </div>
              <br />
              <b-link :href="fileSahRekomTerbit(item)" target="_blank"
                >Link Alternative (File Rekom Sah)</b-link
              >
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import {
  BButton,
  BCard,
  BImg,
  BBadge,
  BSpinner,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormFile,
  // BBadge,
} from "bootstrap-vue";
import BaseNavigation from "@/components/Base/BaseNavigation.vue";
import DividerNavigation from "@/components/Base/DividerNavigation.vue";
// import { required, email } from '@validations'
import apis from "@/api";
import storage from "@/helpers/storage";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BaseNavigation,
    DividerNavigation,
    BButton,
    BCard,
    BFormInput,
    BFormSelect,
    BImg,
    BBadge,
    BSpinner,
    BForm,
    BFormGroup,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    // BBadge,
  },
  data() {
    return {
      required,
      rekomendasi: {
        data: null,
        isLoading: false,
        nextPageUrl: null,
      },
      FilterSipUmum: false,
      SortSipUmum: false,
      filter: {
        reksip_nama_instansi: "",
        reksip_alamat_instansi: "",
        typeSort: "",
        reksip_kategori: "umum",
      },
      optionTypeSort: ["asc", "desc"],
    };
  },
  computed: {
    // https://www.idijakpus.or.id/uploads/krip/krip_file/1473/A2.png
    fileSahRekomTerbit() {
      return (item) => {
        if (item.reksip_sah_ketua_idi_cab != null) {
          if (item.reksip_sah_ketua_idi_cab.includes("https")) {
            return item.reksip_sah_ketua_idi_cab;
          }
        } else {
          return null;
        }
      };
    },
    isPDF() {
      return (item) => {
        if (item.reksip_sah_ketua_idi_cab?.includes(".pdf")) {
          return true;
        }
        return false;
      };
    },
  },
  mounted() {
    document
      .getElementsByClassName("app-wrapper")[0]
      .addEventListener("scroll", this.scrollCallback);
  },
  created() {
    this.GetRekomendasiUmum();
  },
  methods: {
    viewPdf(url) {
      storage.setStorage("pdfUrl", url);
      this.$router.push({ path: "/pdfview" });
    },
    scrollCallback() {
      const element = document.getElementsByClassName("app-wrapper")[0];
      const { scrollTop } = element;
      const { scrollHeight } = element;
      const { clientHeight } = element;
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        if (!this.rekomendasi.isLoading && this.rekomendasi.nextPageUrl) {
          this.GetRekomendasiUmum(this.rekomendasi.nextPageUrl);
        }
      }
    },
    GetRekomendasiUmum(url) {
      this.rekomendasi.isLoading = true;
      apis.rekomendasi
        .getRekomendasi(url, this.filter)
        .then(({ data }) => {
          if (url && this.filter) {
            this.rekomendasi.data = this.rekomendasi.data.concat(data.data);
          } else {
            this.rekomendasi.data = data.data;
          }
          this.rekomendasi.nextPageUrl = data.next_page_url;
        })
        .finally(() => {
          this.rekomendasi.isLoading = false;
        });
    },
    async CancelRekomendasiUmum(ids) {
      try {
        this.$swal({
          title: "Apakah kamu yakin?",
          text: "Rekomendasi Izin Praktik Umum yang sudah dihapus, tidak bisa dikembalikan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            if (result.value) {
              this.$store.commit("app/UPDATE_LOADING_BLOCK", true);
              return apis.rekomendasi.cancelRecomendation({ reksip_id: ids });
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil menghapus rekomendasi izin praktik Umum",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.GetRekomendasiUmum();
          })
          .catch((error) => {
            this.errorHandler(error, "gagal di cancel coba lagi");
          })
          .finally(() => {
            this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
          });
      } catch (error) {
        this.errorHandler(error, "kesalahan sistem");
      } finally {
        this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
      }
    },
    validateFilterKRIP() {
      this.$refs.FilterSipUmum.validate().then((success) => {
        if (success) {
          this.GetRekomendasiUmum();
        }
      });
    },
    validateSortSipUmum() {
      this.$refs.SortSipUmum.validate().then((success) => {
        if (success) {
          this.GetRekomendasiUmum();
        }
      });
    },
  },
};
</script>

<style></style>
